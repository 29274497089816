<template>
  <q-card v-if="Array.isArray(data)">
    <q-card-section v-if="rawData.options.title" class="text-h6">
      {{ rawData.options.title }}
    </q-card-section>

    <q-card-section>
      <q-list
        bordered
        separator
      >
        <q-item
          v-for="(batch, i) in data"
          :key="`${batch}:${i}`"
          v-ripple
          clickable
          @click="handleClick(batch)"
        >
          <q-item-section>{{ batch }}</q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>

  <q-card v-else>
    <q-card-section v-if="rawData.options.title" class="text-h6">
      {{ rawData.options.title }}
    </q-card-section>

    <q-card-section>
      {{ $t('Batch') + ': ' + data }}
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'BatchObject',
  emits: ['click'],
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: [Array, String, Number],
      default () {
        return null
      }
    }
  },
  methods: {
    handleClick (batch) {
      const data = {
        event: 'storage.tasks.task.batch.opened',
        data: batch
      }

      this.$emit('click', data)
    }
  }
}
</script>
